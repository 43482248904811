body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  display:flex; 
  flex-direction:column; 
  background-color: #efefef;
  color: #3a3a3a;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::-webkit-scrollbar {
  width: 0.25rem;
}

body::-webkit-scrollbar-track {
  background: #1e1e24;
}

body::-webkit-scrollbar-thumb {
  background: #26008f;
}

.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.box {
  position: relative;
  width: 90%;
  margin: 0 auto;
  height: auto;
  max-height: 82vh;
  margin-top: calc(100vh - 85vh);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
  z-index: 10;
  
}


@media only screen and (min-width: 768px) {
  .box {
    position: relative;
    width: auto;
    max-width: 90vw;
    margin: 0 auto;
    height: auto;
    max-height: 80vh;
    margin-top: calc(100vh - 85vh);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
    z-index: 10;
  }
}
 
.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(10% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

.footer {
    background-color: #f5f5f5;
    text-align: center;
    margin-top:auto; 
}

.center { 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-signin { 
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
  text-align: center;
}

.logo {
  margin-bottom: 1rem;
  color: #333;;
  background: var(--bg-secondary);
  font-size: 1.5rem;  
  text-transform: uppercase;
  letter-spacing: 0.3ch;
  width: 120px;
  padding-top: 15px;  
  border-radius: 50%;
}

@media only screen and (max-width: 1080px){
  .logo {
    width: 4rem;
    padding-top: 5px;
    padding-left: 0px;
    margin-bottom: 0;
  }
}

.error {
  background-color: red;
}

.table-collapse {
  border-collapse: collapse;
}

.welcome-logo {
  width: 200px;
}

.seperator {  
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

.header {
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  justify-content: space-between;
}


.placeholder-table {
  content: '';
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 70px;
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0
  }
  100% {
    background-position: 800px 0
  }
}

/* Portal Buttons */

.portal-container {
  display: flex;
  justify-content: space-around;
}

@media only screen and (max-width: 768px) {
  .portal-container {
    flex-direction: column;
    justify-content: center;
  }
}
 .mobile-menu-control-wrapper .menu-toggle,
 .mobile-menu-control-wrapper .menu-toggle:hover,
 .mobile-menu-control-wrapper .menu-toggle:focus,
 .has-inline-mobile-toggle #site-navigation.toggled {
  background-color:rgba(0, 0, 0, 0.02)
 }
 .main-navigation,
 .main-navigation ul
 ul {
  background-color:#fff
 }
 .main-navigation .main-nav ul li a,
 .main-navigation .menu-toggle,
 .main-navigation .menu-bar-items {
  color:#000
 }
 .main-navigation .main-nav ul li:not([class*="current-menu-"]):hover > a,
 .main-navigation .main-nav ul li:not([class*="current-menu-"]):focus > a,
 .main-navigation .main-nav ul li.sfHover:not([class*="current-menu-"]) > a,
 .main-navigation .menu-bar-item:hover > a,
 .main-navigation .menu-bar-item.sfHover>a {
  color:#4a4a4a;
  background-color:#fff
 }
 button.menu-toggle:hover,
 button.menu-toggle:focus {
  color:#000
 }
 .main-navigation .main-nav ul li[class*="current-menu-"]>a {
  color:#7f7f7f;
  background-color:#fff
 }
 .main-navigation ul
 ul {
  background-color:#fff
 }
 .main-navigation .main-nav ul ul li
 a {
  color:#000
 }
 .main-navigation .main-nav ul ul li:not([class*="current-menu-"]):hover > a,
 .main-navigation .main-nav ul ul li:not([class*="current-menu-"]):focus > a,
 .main-navigation .main-nav ul ul li.sfHover:not([class*="current-menu-"])>a {
  color:#4a4a4a;
  background-color:#d0d0d0
 }
 .main-navigation .main-nav ul ul li[class*="current-menu-"]>a {
  color:#7f7f7f;
  background-color:#c6c6c6
 }

 @media (max-width:768px) {
  .main-navigation .menu-toggle,
  .sidebar-nav-mobile:not(#sticky-placeholder) {
   display:block
  }
  .main-navigation ul,
  .gen-sidebar-nav,
  .main-navigation:not(.slideout-navigation):not(.toggled) .main-nav > ul,
  .has-inline-mobile-toggle #site-navigation .inside-navigation>*:not(.navigation-search):not(.main-nav) {
   display:none
  }
  .nav-align-right .inside-navigation,
  .nav-align-center .inside-navigation {
   justify-content:space-between
  }
  .has-inline-mobile-toggle .mobile-menu-control-wrapper {
   display:flex;
   flex-wrap:wrap
  }
  .has-inline-mobile-toggle .inside-header {
   flex-direction:row;
   text-align:left;
   flex-wrap:wrap
  }
  .has-inline-mobile-toggle .header-widget,
  .has-inline-mobile-toggle #site-navigation {
   flex-basis:100%
  }
  .nav-float-left .has-inline-mobile-toggle #site-navigation {
   order:10
  }
 }