/* HEADER */
.portal-header {
    text-align: center;
}

.portal-logo {
    padding: 16px 0;
}

/* Navbar */

.portal-nav {
    text-align: center;
    height: 48px;
    width: 100%;
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
}

.portal-nav ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: auto;
    max-width: 1248px;
}

.portal-nav ul li {
    display: inline-flex;
    padding: 12px 0 10px;
}

.portal-nav a {
    text-decoration: none;
    color: #141414;
}


/* FOOTER */

.footer-jl {
    background-color: #f2f2f2;
    padding-top: 40px;
}

.list {
    display: flex;
    align-items: center;
    list-style: none;
    justify-content: space-around;
}

.list a {
    display: inline-block;
    text-decoration: none;
    color: #6b6b6b
}


/* Social Links */

.social-link {
    border-bottom: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
    padding: 24px 16px;
    text-align: center;
}

.social-link ul {
    height: 35px;
    max-width: 480px;
    margin: auto;
}

.social-link ul li {
    line-height: 10px;
}


.social-link svg {
    width: 21px;
    height: 21px;
    overflow: hidden;
}

.u-audible {
    display: inline-block;
    width: 0;
    overflow: hidden;
}

/* Address */

.footer-address {
    margin-left: auto;
    margin-right: auto;
    padding: 0 24px;
    display: flex;
}

.footer-address aside {
    margin-left: 0;
    padding-left: 2rem;
}

/* Copyright */

.copyright {
    text-align: center;
    margin: 0 auto; 
    padding: 16px 0
}

/* Privacy  */

.privacy {
    text-align: center;
    margin: 0 auto; 
    padding: 0.5rem 0;
    border-top: 1px solid #d8d8d8;  
}

.privacy-bar ul {
    margin: auto;
    height: 2rem;
}

/* FORM CONTROLS */

.form {
    display: flex;
    margin-left: -40px;
}

.form-section {
    flex: 0 0 auto;
    width: 100%;
}

@media (min-width: 768px) {
    .form-section {
        width: 50%;
        margin: .5rem;
    }
}

.form-section input {
    width: 100%;
    padding: .375rem .5rem;
    border: 1px solid #ced4da;
    appearance: none;
    line-height: 1.5;
    font-weight: 400;
}

.form-section input[type="file"] {
    overflow: hidden;
    border: none;
    padding: 0;
}


.form-section input[type="date"] {
    width: auto;
}

.form-section input[type="radio"]{
    width: 1em;
}


.form-section input[type="radio"]:checked + label {
    background-color: aquamarine;
    color: #141414;
}


.form-section select {
    padding: .375rem .5rem;
    -moz-padding-start: calc(0.75rem - 3px);
    width: 100%;
    border: 1px solid #ced4da;
    line-height: 1.5;
    font-weight: 400;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    appearance: none;
}

.form-section textarea {
    width: 100%;
    padding: .375rem .5rem;
    border: 1px solid #ced4da;
}

.form-button {
    text-align: center;
    user-select: none;
    padding: .75rem 1.5rem;
    border: 1px solid transparent;
    font-size: 1rem;
    vertical-align: middle;
    background-color: #d8d8d8;
    color: #000;
}

.form-button:hover {
    text-align: center;
    user-select: none;
    padding: .75rem 1.5rem;
    border: 1px solid transparent;
    font-size: 1rem;
    vertical-align: middle;
    background-color: #acacac;
}

.form-button:disabled {
    background-color: #d8d8d8;
    color: #6b6b6b;
}

.required {
    color: red;
}

/* DATE */

.date-selector {
    overflow: auto;
}

.date-selector input[type="radio"]:disabled + label {
    background-color: #f2f2f2;
    color: #ced4da;
}


.form-section input[type="radio"]:checked + label {
    background-color: rgb(98, 98, 98);
    color: #fff;
}

.date-selector ul {
    list-style: none;
    display: inline-flex;
    overflow: auto;
    white-space: nowrap;
}

.date-selector ul li {
    margin: 2rem;
}