body {
  font-size: .875rem !important;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  width: 5rem;
  height: 100vh;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  transition: width 200ms ease;
}

@media only screen and (min-width: 1081px){
  .sidebar {
    position: fixed;
    width: 18rem;
    height: 100vh;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  }
}

.sidebar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.sidebar-item {
  width: 100%;
}

.sidebar-item:last-child {
  margin-top: auto;
}

.sidebar .nav-link {
  display: flex;
  align-items: center;
  line-height: 10px;
  font-weight: 500;
  color: #333;
}

.settings-col {
  flex: 0 0 auto;
  width: 16.66666667% !important;
}

@media only screen and (max-width: 1080px){
  .settings-col {
    width: auto !important;
    margin-left: 1rem;
  }    
}

.sidebar .nav-text {
  display: block;
  transition: display 200ms ease;
}

@media only screen and (max-width: 1080px){
  .sidebar .nav-text {
    display: none;
    transition: display 200ms ease;
  }
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar-icon {
  margin-right: 4%;
  width: 1.5rem;
  height: 1.5rem;
}

@media only screen and (max-width: 1080px){
  .sidebar-icon {
    width: 2rem;
    height: 2rem;
    transition-property: width;
    transition: 600ms ease;
  }
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

.sidebar-user {
  display: block;
}
@media screen and (max-width: 1080px) {
  .sidebar-user {
    display: none;
  }
}

.sidebar-avatar {
  width: 70px !important;
  padding-left: 25px !important;
  padding-bottom: 15px;
  border-radius: 50%;
}

@media screen and (max-width: 1080px) {
  .sidebar-avatar {
    width: 90px !important;
    padding-left: 25px !important;
    padding-bottom: 15px;
    border-radius: 50%;
  }
}


.sidebar-user-name {
  display: none;
  margin-bottom: 0px;
  font-weight: 700;
}

@media only screen and (min-width: 1080px){
  .sidebar-user-name {
    display: block;
    margin-bottom: 0px;
    font-weight: 700;
  }
}

.sidebar-user-email {
  display: none;
  margin-bottom: 0px;
}

@media only screen and (min-width: 1080px){
  .sidebar-user-email {
    display: block;
    margin-bottom: 0px;
  }  
}

/*
 * Navbar
 */

.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.navbar .form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.navbar .form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

/*
 * Utilities
 */

.border-top { border-top: 1px solid #e5e5e5; }
.border-bottom { border-bottom: 1px solid #e5e5e5; }


/*
 * Main
 */

main {
  margin-left: 5rem;
  height: 100vh;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  transition: width 200ms ease;
}

@media only screen and (min-width: 1081px){
  main {
    margin-left: 18rem;
    height: 100vh;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  }
}

body {
  background-color: #efefef;
  color: #3a3a3a
}

a {
  color: #1e73be
}

a:hover,
a:focus,
a:active {
  color: #000
}

.grid-container {
  max-width: 1400px
}

.site-header .header-image {
  width: 100px
}

:root {
  --contrast: #222;
  --contrast-2: #575760;
  --contrast-3: #b2b2be;
  --base: #f0f0f0;
  --base-2: #f7f8f9;
  --base-3: #fff;
  --accent: #07023A
}

body {
  line-height: 1.5
}

.main-navigation .main-nav ul ul li a {
  font-size: 14px
}

h1 {
  font-weight: 300;
  font-size: 40px
}

h2 {
  font-weight: 300;
  font-size: 30px
}

h3 {
  font-size: 20px
}

h4 {
  font-size: inherit
}

h5 {
  font-size: inherit
}

@media (max-width:768px) {
  .main-title {
      font-size: 30px
  }

  h1 {
      font-size: 30px
  }

  h2 {
      font-size: 25px
  }
}

.top-bar a:hover {
  color: #303030
}

.site-header {
  background-color: #fff;
  color: #3a3a3a
}

.site-header a {
  color: #3a3a3a
}

.main-navigation,
.main-navigation ul ul {
  background-color: #fff
}

.main-navigation .main-nav ul li a,
.main-navigation .menu-toggle,
.main-navigation .menu-bar-items {
  color: #000
}

.main-navigation .main-nav ul li:not([class*="current-menu-"]):hover>a,
.main-navigation .main-nav ul li:not([class*="current-menu-"]):focus>a,
.main-navigation .main-nav ul li.sfHover:not([class*="current-menu-"])>a,
.main-navigation .menu-bar-item:hover>a,
.main-navigation .menu-bar-item.sfHover>a {
  color: #4a4a4a;
  background-color: #fff
}

button.menu-toggle:hover,
button.menu-toggle:focus {
  color: #000
}

.main-navigation .main-nav ul li[class*="current-menu-"]>a {
  color: #7f7f7f;
  background-color: #fff
}

.main-navigation ul ul {
  background-color: #fff
}

.main-navigation .main-nav ul ul li a {
  color: #000
}

.main-navigation .main-nav ul ul li:not([class*="current-menu-"]):hover>a,
.main-navigation .main-nav ul ul li:not([class*="current-menu-"]):focus>a,
.main-navigation .main-nav ul ul li.sfHover:not([class*="current-menu-"])>a {
  color: #4a4a4a;
  background-color: #d0d0d0
}

.main-navigation .main-nav ul ul li[class*="current-menu-"]>a {
  color: #7f7f7f;
  background-color: #c6c6c6
}

@media (max-width:768px) {

  .main-navigation .menu-bar-item:hover>a,
  .main-navigation .menu-bar-item.sfHover>a {
      background: none;
      color: #000
  }
}

.inside-header {
  padding: 40px
}

.inside-header.grid-container {
  max-width: 1480px
}

@media (max-width:768px) {

  .main-navigation .menu-toggle,
  .sidebar-nav-mobile:not(#sticky-placeholder) {
      display: block
  }

  .main-navigation ul,
  .gen-sidebar-nav,
  .main-navigation:not(.slideout-navigation):not(.toggled) .main-nav>ul,
  .has-inline-mobile-toggle #site-navigation .inside-navigation>*:not(.navigation-search):not(.main-nav) {
      display: none
  }

}

@media (max-width: 768px) {
  .columns {
    flex-direction: column;
  }
/* 
  .columns > section {
    text-align: center;
  } */

  .columns > ul {
    list-style: none;
  }
}


.site-info {
  color: #fff;
  background-color: #222;
}

.inside-site-info {
  padding: 20px;
}